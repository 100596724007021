<template>
  <div class="container-fluid-max mt-3">
    <h1 class="text-center">{{ t("user.user") }}</h1>
    <div class="text-end gws_control_bar d-flex justify-content-between align-items-center">
      <div class="form-floating">
        <input type="text" class="form-control" id="search" placeholder="Suche" v-model="searchTerm" />
        <label for="search">{{ t("general.search") }}</label>
      </div>
      <router-link
        v-if="currentUser.role === 0"
        to="/user/create"
        class="btn btn-success p-0"
        :title="t('general.new')"
      >
        <svg class="bi" width="32" height="32" fill="currentColor">
          <use xlink:href="/assets/img/bootstrap-icons.svg#plus"></use>
        </svg>
      </router-link>
    </div>
    <div class="p-3">
      <table-lite
        class="gws_data_tbl"
        :has-checkbox="false"
        :is-loading="table.isLoading"
        :is-re-search="table.isReSearch"
        :is-static-mode="true"
        :columns="table.columns"
        :rows="table.rows"
        :total="table.totalRecordCount"
        :sortable="table.sortable"
        :messages="table.messages"
        :pageSize="table.pageSize"
        @do-search="doSearch"
        @is-finished="tableLoadingFinish"
      ></table-lite>
    </div>
    <teleport to="#modals">
      <transition name="modal">
        <div v-if="modal" class="modal d-block" tabindex="0">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title">{{ t("user.user") }} {{ t("general.delete") }}</h3>
                <button
                  @click="modal = !modal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body" v-if="user">
                <p>{{ t("user.deleteThisUser") }}?</p>
                <dl>
                  <dt>ID</dt>
                  <dd>{{ user.id }}</dd>
                  <dt>{{ t("user.email") }}</dt>
                  <dd>{{ user.email }}</dd>
                  <dt>{{ t("user.name") }}</dt>
                  <dd>{{ user.name }}</dd>
                </dl>
              </div>
              <div class="modal-body" v-else>
                <p>{{ t("user.user") }} {{ t("error.notFound") }}.</p>
              </div>
              <div class="modal-footer">
                <button v-if="user" type="button" class="btn btn-primary me-2" @click="delusr(user.id)">
                  {{ t("general.delete") }}
                </button>
                <button v-if="user" type="button" class="btn btn-secondary" @click="modal = !modal">
                  {{ t("general.cancel") }}
                </button>
                <button v-else type="button" class="btn btn-secondary" @click="updateList">
                  {{ t("general.update") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>
<script>
import { computed, reactive, ref } from "vue";
// import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import TableLite from "vue3-table-lite";
import { useI18n } from "vue-i18n/index";
import axios from "axios";
import storeConfig from "../../store/config";
import { useToast } from "vue-toastification";
export default {
  name: "UserList",
  components: {
    TableLite,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const searchTerm = ref("");
    const router = useRouter();
    const toast = useToast();

    if (store.getters.jwt) {
      store.dispatch("loadUsers");
    }

    const table = reactive({
      // isLoading: true,
      isReSearch: false,
      pageSize: 25,
      columns: [
        {
          label: "ID",
          field: "id",
          width: "3%",
          sortable: true,
          isKey: true,
        },
        {
          label: t("datatbl.name"),
          field: "name",
          width: "10%",
          sortable: true,
          display: function (row) {
            return (
              '<a data-id="' +
              row.id +
              '" class="is-rows-el edit-btn cursor-pointer" title="' +
              t("general.edit") +
              '">' +
              row.name +
              "</a>"
            );
          },
        },
        {
          label: t("datatbl.email"),
          field: "email",
          width: "15%",
          sortable: true,
        },
        {
          label: t("datatbl.role"),
          field: "roleDescription",
          width: "10%",
          sortable: true,
        },
        {
          label: "Aktionen",
          field: "quick",
          width: "10%",
          display: function (row) {
            if (store.getters.user.role == 0 || store.getters.user.id == row.id) {
              let edit =
                '<button type="button" data-id="' +
                row.id +
                '" class="btn btn-success btn-icon me-2 is-rows-el edit-btn" title="' +
                t("general.edit") +
                '"><svg width="20" height="20" ' +
                'class="bi" fill="currentColor">' +
                '<use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>' +
                "</svg></button>";
              let del =
                '<button type="button" data-id="' +
                row.id +
                '" class="btn btn-danger btn-icon me-2 is-rows-el delete-btn" title="' +
                t("general.delete") +
                '"><svg width="20" height="20" ' +
                'class="bi" fill="currentColor">' +
                '<use xlink:href="/assets/img/bootstrap-icons.svg#x-circle"></use>' +
                "</svg></button>";
              let cu = "";
              if (row.id == store.getters.user.id) {
                cu =
                  '<button type="button" data-id="' +
                  row.id +
                  '" class="btn btn-secondary btn-icon me-2 is-rows-el edit-btn" title="' +
                  t("user.loggedinUser") +
                  '"><svg width="20" height="20" ' +
                  'class="bi" fill="currentColor">' +
                  '<use xlink:href="/assets/img/bootstrap-icons.svg#person-circle"></use>' +
                  "</svg></button>";
              }
              if (row.id === 1 || (store.getters.user.id == row.id && store.getters.user.role !== 0)) {
                return edit + cu;
              } else {
                return edit + cu + del;
              }
            } else {
              return "";
            }
          },
        },
      ],
      rows: computed(() => {
        return store.getters.activeUsers.filter(
          (x) =>
            x.email.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
            x.roleDescription.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
            x.name.toLowerCase().includes(searchTerm.value.toLowerCase())
        );
      }),
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "id",
        sort: "asc",
      },
      messages: {
        pagingInfo: t("datatbl.pagingInfo") + " ",
        pageSizeChangeLabel: t("datatbl.pageSizeChangeLabel") + " ",
        gotoPageLabel: " " + t("datatbl.gotoPageLabel") + " ",
        noDataAvailable: t("datatbl.noDataAvailable"),
      },
    });

    const doSearch = (offset, limit, order, sort) => {
      table.sortable.order = order;
      table.sortable.sort = sort;
      if (sort == "asc") {
        store.getters.activeUsers.sort((a, b) => a[order].toString().localeCompare(b[order]));
      } else {
        store.getters.activeUsers.sort((a, b) => b[order].toString().localeCompare(a[order]));
      }
    };

    const modal = ref(false);

    const tableLoadingFinish = (elements) => {
      table.isLoading = false;
      Array.prototype.forEach.call(elements, function (element) {
        if (element.classList.contains("edit-btn")) {
          element.addEventListener("click", function () {
            router.push({ name: "UserEdit", params: { id: this.dataset.id } });
          });
        }
        if (element.classList.contains("delete-btn")) {
          element.addEventListener("click", function () {
            // router.push({ name: "UserDelete", params: { id: this.dataset.id } });
            // console.log(this, this.dataset.id, event);
            store.dispatch("loadUser", this.dataset.id);
            modal.value = true;
          });
        }
      });
    };

    async function delusr(id) {
      try {
        // console.log(id);
        let cuId = store.getters.user.id;
        modal.value = !modal.value;
        await store.dispatch("addLoading", 1);
        let del = await axios.delete(storeConfig.apiBaseUrl + "user/" + id);
        await store.dispatch("loadUsers");
        await store.dispatch("addLoading", -1);
        // console.log(del.status);
        if (del.status === 200) {
          toast.success(t("user.user") + " " + t("general.successfully") + " " + t("general.deleted") + ".");
          if (id === cuId) {
            // await store.dispatch("logout");
            router.push("/login");
          }
        } else {
          toast.error(t("user.user") + " " + t("general.delete") + ": " + t("error.errorOccured") + ".");
        }
      } catch (err) {
        toast.error(err.response.data.message);
        await store.dispatch("addLoading", -1);
      }
    }

    async function updateList() {
      modal.value = false;
      if (store.getters.jwt) {
        store.dispatch("loadUsers");
      }
    }

    return {
      searchTerm,
      users: computed(() => store.getters.activeUsers),
      user: computed(() => store.getters.editUser),
      currentUser: computed(() => store.getters.user),
      table,
      doSearch,
      tableLoadingFinish,
      modal,
      delusr,
      updateList,
      t,
    };
  },
};
</script>
<style></style>
